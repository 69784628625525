import React from "react";
import {Routes, Route} from "react-router-dom"

import HomeView from "./HomeView";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Navigate from "../components/Navigate";

class Router extends React.Component{
    render(){
        return(
            <div>
                <Header />
                <Routes>
                    <Route exact path="/" element={<HomeView />} />
                    <Route exact path="/demo" element={<Navigate />} />
                </Routes>
                <Footer />
            </div>
        )
    }
}

export default Router;