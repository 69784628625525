import { BrowserRouter } from 'react-router-dom';
import "./App.css";
import "./bootstrap.css";
import Router from './pages/Router';

function App() {
  return (
    <div>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
    </div>
  );
}

export default App;
