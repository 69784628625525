import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../App.css";
import "../bootstrap.css";

import { Logo, hamburger_icon } from "../pages/icons";

const menuItems = [
  // { item: "Find trials", url: "https://trials.me/" },
  // { item: "Sponsors", url: "https://patientrials.com/" },
  { item: "About", url: "https://patientrials.com/about/" },
  // { item: "Blog", url: "/blog" },
];

const signUp = () => {
  //localStorage.setItem("currentPage", window.location.href);
  window.open(`https://patientrials.com/request-demo/`,"_blank")
};

const Header = (props) => {
  const { showHeader } = props;
  const location = useLocation();
  const itemClick = (name) => {
    setClickedItem(name);
  };
  const [clickedMenuItem, setClickedItem] = useState("");
  const MenuItem = (i, url, itemName, activeState) => {
    return (
      <li
        key={i}
        onClick={() => itemClick(itemName)}
        className={
          itemName === clickedMenuItem ? "nav-item active" : activeState
        }
      >
        <a href={url} className="nav-link">
          {itemName}
        </a>
      </li>
    );
  };

  return (
    <div style={{ display: `${showHeader === false && "none"}` }}>
      <div className="menubar container-fluid">
        <nav className="navbar navbar-expand-lg container">
          <a href="/">
              <img
                className="navbar-brand logo"
                alt="logo"
                src={Logo}
              ></img>
          </a>
          <button
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#menu"
          >
            <span className="navbar-toggler-icon">
              <img src={hamburger_icon} alt="header menu icon"></img>
            </span>
          </button>
          <div id="menu" className="collapse navbar-collapse">
            
            <ul className="navbar-nav ml-auto">
              {menuItems.map((menuitem, i) =>
                MenuItem(i, menuitem.url, menuitem.item, "nav-item")
              )}
            </ul>
            <button
              onClick={() => signUp()}
              rel="noopener noreferrer"
              className="signin btn btn-lg ml-2"
              target="_blank"
            >
              REQUEST DEMO
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
