import Logo from "../images/logo.png";
import hamburger_icon from "../images/list.png";
import facebookIcon from "../images/Facebook.png";
import linkedinIcon from "../images/Twitter.png";
import twitterIcon from "../images/LinkedIN.png";
import mappin from "../images/map-pin.png";
import your_brand from "../images/your_brand.png";
import your_brand_bg from "../images/your_brand_bg.png";
import efficiency from "../images/efficiency.png";
import recruitment_cost from "../images/cost.png";
import footer_top_bg from "../images/footer_top_bg.png";
import qr_code from "../images/qr_code.png";

import f1 from "../images/features/1.png";
import f2 from "../images/features/2.png";
import f3 from "../images/features/3.png";
import f4 from "../images/features/4.png";
import f5 from "../images/features/5.png";
import f6 from "../images/features/6.png";
import f7 from "../images/features/7.png";
import f8 from "../images/features/8.png";
import f9 from "../images/features/9.png";
import f10 from "../images/features/10.png";

export {
  Logo,
  hamburger_icon,
  facebookIcon,
  linkedinIcon,
  twitterIcon,
  mappin,
  your_brand,
  your_brand_bg,
  efficiency,
  recruitment_cost,
  footer_top_bg,
  f1,
  f2,
  f3,
  f4,
  f5,
  f6,
  f7,
  f8,
  f9,
  f10,
  qr_code
};
