import React from "react";
import {
  efficiency,
  f1,
  f2,
  f3,
  f4,
  f5,
  f6,
  f7,
  f8,
  f9,
  f10,
  recruitment_cost,
  qr_code,
} from "./icons";

const partners = [
  "Pfizer",
  "Roche",
  "JnJ",
  "Novartis",
  "Merck",
  "Walmart",
  "Sanofi",

  "AbbVie",

  "GSK",

  "Amgen",

  "Gilead",

  "BMS",

  "AstraZeneca",

  "Lilly",
  "CVS",
  "Bayer",

  "NovoNordisk",

  "Takeda",

  "Celgene",

  "Shire",

  "Boehringer-Ingelheim",
  // "SyncForce Client",

  "Allergan",

  "Teva",

  "Mylan",

  "Astellas",

  "Biogen",

  "CSL",

  "DaiichiSankyo",

  "Otsuka",

  "UCB",

  "Servier",

  "BauschHealth",

  "Eisai",

  "Abbott",

  "Fresenius",

  "SunPharma",

  "Grifols",

  "Alexion",

  "Regeneron",

  "Chugai",

  "Sumitomo-Pharma",

  "Menarini",

  "Sino",

  "Vertex",

  "EndoHealth",

  "Mitsubishi-Pharma",

  "Ipsen",

  // "Jiangsu Hengrui Medicine",

  "Mallinckrodt",

  "STADA",

  "Ferring",
];

const FeatureItem = (props) => {
  return (
    <div style={{ height: 270 }} className="features_item p-5 m-0">
      <img src={props.icon} alt="item"></img>
      <p className="mb-0 mt-4">{props.description}</p>
    </div>
  );
};

class HomeView extends React.Component {
  intervalid = 0;
  constructor(props) {
    super();
    this.state = {
      partner_name: "{Your Company}",
      counter: 0,
    };
  }

  componentDidMount() {
    this.intervalid = setInterval(() => {
      console.log("changing counter value");
      let updated_partners = []
      for(let i = 0; i< partners.length; i++){
        // updated_partners.push( partners[i])
        if(i % 2 == 0){
          updated_partners.push("Your Company")
        }else{
          updated_partners.push(partners[Math.floor(Math.random()*partners.length)])
        }
      }
      if (this.state.counter >= partners.length) {
        this.setState({ counter: 0, partner_name: "Your Company" });
      } else
        this.setState({
          counter: this.state.counter + 1,
          partner_name:
            "{" + updated_partners[this.state.counter]
            // .replace(/\s/g, "") 
            + "}",
        });
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalid);
  }

  render() {
    return (
      <div>
        <div className="my-4 py-4 container">
          <div className="your_brand col-sm-12 col-xs-12 px-0">
            <div className="d-flex align-items-center justify-content-center">
              <div className="your_brand_bg w-100">
                <div style={{ textAlign: "center" }}>
                  <h3 style={{color : '#000000'}} className="brand_title mt-3 mb-0">Your Brand</h3>
                  <h3 style={{color : '#000000'}} className="brand_title mb-0"> Our Infrastructure</h3>
                  <h3 style={{color : '#000000', fontWeight : 'normal'}} className="brand_title mb-0"> The Future of Clinical Trials</h3>

                  <h6 style={{ color: "#000000" }} className="mt-4">
                    <a
                      rel="noreferrer"
                      href="https://patientrials.com/request-demo/"
                      target="_blank"
                    >
                      Register here
                    </a>
                    {` for ${this.state.partner_name}.etrials.io`}
                  </h6>
                  <p
                    style={{ color: "#000000" }}
                    className="mb-0 brand_subtitle mt-4"
                  >
                    To learn more,
                  </p>
                  <button
                    onClick={() =>
                      window.open(
                        "https://patientrials.com/request-demo/",
                        "_blank"
                      )
                    }
                    className="signin btn btn-lg my-3 px-lg-5 px-md-5"
                  >
                    SCHEDULE A DEMO
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="my-3 info">
            {/* <div className="row px-0 mx-0">
              <div
                style={{
                  background: "#FFFFFF",
                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
                }}
                className="col-lg-6 col-sm-12 col-xs-12 px-0"
              >
                <img
                  style={{ width: "95%" }}
                  src={efficiency}
                  alt="efficiency"
                ></img>
                <h6 className="efficiency_row_item mt-4 mb-4">
                  Improve efficiencies across your studies for timely
                  recruitment
                </h6>
              </div>
              <div
                style={{
                  background: "#FFFFFF",
                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
                }}
                className="col-lg-6 col-sm-12 col-xs-12 px-0"
              >
                <img
                  className="ml-lg-4"
                  style={{ width: "95%" }}
                  src={recruitment_cost}
                  alt="cost"
                ></img>
                <h6 className="efficiency_row_item mt-4 mb-4 ml-lg-4">
                  Reduced Cost by consolidating the recruitment process
                </h6>
              </div>
            </div> */}
            <h4 className="mb-0">
              Experience White-Label Solutions on a Robust, Connected
              Infrastructure
            </h4>
            <br />
            <h6>
              <strong>Designed with pharmaceutical companies, sponsors, CROs and Research Institutions in mind, </strong>
              etrials.io brings your brand to the forefront of clinical trial
              management by offering tailored, white-label solutions on a
              robust, connected infrastructure
              <br />
              <br />
              With etrials.io, you have the <strong>power to promote your studies</strong> for
              efficient patient recruitment and seamless onboarding. Our
              integrated enterprise platform enables a smooth and efficient
              operational workflow that ultimately enhances the clinical trial
              experience for your teams and patients alike.
              <br />
              <br />
              etrials.io empowers pharmaceutical companies , sponsors, CROs and Research Institutions to
              design the perfect team structure for each study. Create your own 
              <strong> dedicated study nurse concierge team</strong> or form a partnership with an
              existing nurse concierge team – whatever works best for your
              unique needs.
              <br />
              <br />
              <strong>Leverage our innovative, patient-centric platform</strong> that is designed
              for seamless interoperability with various CTMSs and EDCs. Plug
              and play with minimum configuration for rapid site selection,
              independent investigator invites, site monitoring, Patient
              Recruitment, onboarding, engagement and more. All under your
              brand, ensuring consistent and clear communication with your
              audience.
              <br />
              <br />
              In addition to our advanced technological solutions, etrials.io
              users gain access to our suite of services, including <strong>our partner
              network</strong> of Nurse Concierge services and Trials.me patient
              recruitment platform. Combined, these services provide a
              comprehensive, end-to-end solution for clinical trial management,
              all within your brand's identity.
              <br />
              <br />
              <strong>At etrials.io, we put your brand first.</strong> We provide the tools and
              technology you need to conduct successful, efficient clinical
              trials, while maintaining your brand's identity and reputation
            </h6>
          </div>
        </div>
        <div style={{ backgroundColor: "#E5E5E5" }} className="container-fluid">
          <div className="py-5 container">
            <h4 className="features_title">Features</h4>
            <div className="row mx-1 px-0">
              <div className="col-lg-9 px-0">
                <div className="row my-3 mr-1">
                  <div className="col-lg-4">
                    <FeatureItem
                      icon={f1}
                      description={"Your white-labled space on etrials.io"}
                    />
                  </div>
                  <div className="col-lg-4">
                    <FeatureItem
                      icon={f2}
                      description={
                        "Deep link for each for easy promotion on social and other media channels"
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <FeatureItem
                      icon={f3}
                      description={
                        "Promote all your studies for timely recruitment"
                      }
                    />
                  </div>
                </div>
                <div className="row my-3 mr-1">
                  <div className="col-lg-4">
                    <FeatureItem
                      icon={f4}
                      description={
                        "Connected Infrastructure between Sponsors & Sites"
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <FeatureItem
                      icon={f5}
                      description={"Onboard your sites effortlessly"}
                    />
                  </div>
                  <div className="col-lg-4">
                    <FeatureItem
                      icon={f6}
                      description={
                        "Deploy Central Nurse Concierge for secondary screening"
                      }
                    />
                  </div>
                </div>
                <div className="row my-3 mr-1">
                  <div className="col-lg-4">
                    <FeatureItem
                      icon={f7}
                      description={"Onboard Gig Economy worker as Study Nurse"}
                    />
                  </div>
                  <div className="col-lg-4">
                    <FeatureItem
                      icon={f8}
                      description={"Study Recruitment Stats dashboards"}
                    />
                  </div>
                  <div className="col-lg-4">
                    <FeatureItem
                      icon={f9}
                      description={
                        "Participant Onboarding with eConsent and TeleHealth option"
                      }
                    />
                  </div>
                </div>
                <div className="row my-3 mr-1">
                  <div className="col-lg-4">
                    <FeatureItem
                      icon={f10}
                      description={
                        "Manage all study assets at a single location with single push to all sites"
                      }
                    />
                  </div>
                  <div className="col-lg-4">
                    <FeatureItem
                      icon={f2}
                      description={"Launch & Promote Your Trials with Ease"}
                    />
                  </div>
                  <div className="col-lg-4">
                    <FeatureItem
                      icon={f3}
                      description={
                        "Effortless Study Promotion on a Robust Platform"
                      }
                    />
                  </div>
                </div>
              </div>
              <div onClick={()=> window.open("/demo", "_blank")} className="col-lg-3 px-0 my-3">
                <div className="acceptance_criteria py-5 px-4">
                  {/* <h6>Acceptance Criteria : </h6>
                  <p className="mb-0 mt-4">None</p> */}
                  <h4 style={{cursor: 'pointer'}}>Get started with etrials.io today</h4>
                  <h6 style={{cursor: 'pointer'}} className="mb-0 mt-2">Transform your clinical trials with a personalized solution. Get started with etrials.io today. Let's redefine the world of clinical trials together.</h6>
                  <div className="d-flex align-items-center justify-content-center">
                    <img style={{height: 225}} className="mt-3" src={qr_code} alt="qr_code"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer_top py-5">
            <div className="d-flex align-items-center justify-content-center">
              <div className="row">
                <div className="col-lg-8">
                  <h6>To learn more,</h6>
                </div>
                <div className="col-lg-2">
                  <button className="signin btn btn-md">SCHEDULE NOW</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeView;
