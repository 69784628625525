import React from "react";
import "../App.css";
import "../bootstrap.css";
import {
  Logo,
  facebookIcon,
  twitterIcon,
  linkedinIcon,
  mappin,
} from "../pages/icons";

const Footer = (props) => {
  const { showFooter } = props;
  return (
    <div className="footer p-0 container-fluid">
      {showFooter === false ? null : (
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-3 pl-2 mt-5">
              <a href={"/"}>
                <img alt="logo" style={{ height: "40px" }} src={Logo}></img>
              </a>
              <p
                style={{
                  margin: "10px 0px 0px 0px",
                  fontSize: "14px",
                  color: "black",
                  fontWeight: "bold",
                  fontFamily: "LatoRegular",
                }}
              >
                Patient Centric Platform for Clinical Trials
              </p>
              <div className="mt-5 ml-1 row d-flex align-items-center justify-content-left">
                <a
                  style={{ textDecoration: "none" }}
                  className="mr-3"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={"https://www.facebook.com/patientrials"}
                >
                  <img
                    style={{ height: "30px", width: "30px" }}
                    alt="facebook_icon"
                    src={facebookIcon}
                  ></img>
                </a>
                <a
                  style={{ textDecoration: "none" }}
                  className="mr-3"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={"https://twitter.com/patientrials"}
                >
                  <img
                    style={{ height: "30px", width: "30px" }}
                    alt="facebook_icon"
                    src={linkedinIcon}
                  ></img>
                </a>
                <a
                  style={{ textDecoration: "none" }}
                  className="mr-3"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={"https://www.linkedin.com/company/patientrials/"}
                >
                  <img
                    style={{ height: "30px", width: "30px" }}
                    alt="facebook_icon"
                    src={twitterIcon}
                  ></img>
                </a>
              </div>
            </div>
            <div className="findtrials col-sm-4 col-md-2 col-lg-2 mt-5">
              <h2
                style={{
                  fontSize: "14px",
                  fontFamily: "LatoRegular",
                  fontWeight: "bold",
                  color: "#006D9C",
                }}
              >
                FIND TRIALS
              </h2>
              <br></br>
              {["COVID-19", "Cancer", "Cardiopathy", "Nephritis", "Eczema"].map(
                (link, i) =>
                  link === "Chronic Condition" ? (
                    <div>
                      <h3
                        key={i}
                        style={{
                          fontSize: "14px",
                          fontFamily: "LatoRegular",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        {"Chronic Condition"}
                      </h3>
                      <ul className="pl-4">
                        {[
                          "Diabetes",
                          "Nephrology",
                          "Pulmonology",
                          "Dematology",
                        ].map((subtitle, i) => (
                          <li
                            key={i}
                            style={{
                              fontSize: "14px",
                              fontFamily: "LatoRegular",
                              fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            {subtitle}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <a
                      key={i}
                      href={`https://trials.me/results?q=${link}&in=USA`}
                      style={{ cursor: "pointer" }}
                    >
                      <h3
                        style={{
                          fontSize: "14px",
                          fontFamily: "LatoRegular",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        {link}
                      </h3>
                    </a>
                  )
              )}
            </div>
            <div className="links col-sm-4 col-md-2 col-lg-2 mt-5">
              <h2
                style={{
                  fontSize: "14px",
                  fontFamily: "LatoRegular",
                  fontWeight: "bold",
                  color: "#006D9C",
                }}
              >
                LINKS
              </h2>
              <br></br>
              {[
                { title: "Home", url: "/" },
                {
                  title: "Partners",
                  url: "https://patientrials.com/",
                },
                {
                  title: "About Us",
                  url: "https://patientrials.com/about/",
                },
                {
                  title: "Contact Us",
                  url: "https://patientrials.com/request-demo/",
                },
              ].map((link, i) => (
                <a key={i} href={link.url}>
                  <h3
                    style={{
                      fontSize: "14px",
                      fontFamily: "LatoRegular",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {link.title}
                  </h3>
                </a>
              ))}
            </div>
            <div className="links col-sm-4 col-md-2 col-lg-2 mt-5">
              <h2
                style={{
                  fontSize: "14px",
                  fontFamily: "LatoRegular",
                  fontWeight: "bold",
                  color: "#006D9C",
                }}
              >
                SIGN IN
              </h2>
              <br></br>
              {[
                {
                  title: "Patients",
                  url: `https://trials.me/enroll/STU01009`,
                },
                {
                  title: "Administrator",
                  url: "https://patientrialsprod.service-now.com/patientrials_u18?id=patientrials_register&sysparm_domain_restore=false&sysparm_stack=no",
                },
              ].map((link, i) => (
                <a key={i} href={link.url}>
                  <h3
                    style={{
                      fontSize: "14px",
                      fontFamily: "LatoRegular",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {link.title}
                  </h3>
                </a>
              ))}
            </div>
            <div className="col-sm-12 col-md-8 col-lg-3 mt-5 px-0">
              <div className="row">
                <p
                  className="col-4 mb-0 ml-3"
                  style={{
                    fontSize: "14px",
                    fontFamily: "LatoRegular",
                    fontWeight: "bold",
                    color: "#006D9C",
                  }}
                >
                  EMAIL
                </p>
                <a
                  href="mailto:hello@patientrials.com"
                  style={{
                    fontSize: "14px",
                    color: "#C86007",
                    textDecoration: "underline",
                  }}
                  className="mb-0"
                >
                  hello@patientrials.com
                </a>
              </div>
              <br></br>
              <div className="my-1 text-left d-flex align-items-start justify-content-between">
                <p
                  className="col-4 mb-0"
                  style={{
                    fontSize: "14px",
                    fontFamily: "LatoRegular",
                    fontWeight: "bold",
                    color: "#006D9C",
                  }}
                >
                  USA OFFICES
                </p>
                <div>
                  {[
                    "WeWork Legacy West - 7700 Windrose, Plano, TX 75024, USA",
                    "MATTER - 222 Merchandise Mart Plaza, Suite 1230, Chicago, IL 60654, USA",
                  ].map((office, i) => (
                    <div className="mb-3" key={i}>
                      {/* <p
                      style={{
                        padding: "0px",
                        fontSize: "14px",
                        color: "black",
                        fontWeight: "bold",
                      }}
                      className="mb-0 p-0"
                    >
                      {office.split("-")[0]}
                    </p> */}
                      <p
                        style={{
                          padding: "0px",
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "bold",
                        }}
                        className="mb-0 p-0"
                      >
                        {office}
                        <span className="ml-2">
                          <img alt="mapicon" src={mappin}></img>
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="my-1 text-left d-flex align-items-start justify-content-between">
                <p
                  className="col-4 mb-0"
                  style={{
                    fontSize: "14px",
                    fontFamily: "LatoRegular",
                    fontWeight: "bold",
                    color: "#006D9C",
                  }}
                >
                  INDIA OFFICES
                </p>
                <div>
                  {[
                    "#176, 4th Floor, Adarsh Eco Place, KIADB EPIP 2nd phase, Whitefield, Bengaluru, Karnataka 560066, India",
                  ].map((office, i) => (
                    <div key={i}>
                      <p
                        style={{
                          padding: "0px",
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "bold",
                        }}
                        className="mb-0 p-0"
                      >
                        {office}
                        <span className="ml-2">
                          <img alt="mappin" src={mappin}></img>
                        </span>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="copyright mx-auto my-3 text-center">
              <h3>
                © Copyright 2018 — PatienTrials, a company of PatienTrials Inc.
              </h3>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
